import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Section, { SectionHeader } from '../../components/Section'
import Card from '../../components/Card'

const Projects = () => {
    const data = useStaticQuery(graphql`
    query Projects {
        allContentfulProject(sort: {order: DESC, fields: updatedAt}) {
          edges {
            node {
              id
              title
              type
              url
              description {
                description
              }
              image {
                gatsbyImageData(placeholder: DOMINANT_COLOR)
              }
              attachment {
                file {
                  url
                }
              }
            }
          }
        }
      }
    `)
    const projects = data.allContentfulProject.edges
    return (
        <Section>
        <SectionHeader title="Projects" subtitle="A selection of recent independent and contractual work" />
        <div className="grid sm:grid-cols-3 gap-4">
            {
                projects.map(project => {
                    const {title, type, description, url, image, attachment } = project.node
                    return (
                        <Card 
                            key={title}
                            title={title} 
                            overline={type} 
                            description={description.description}
                            image={image}
                            url={url}
                            attachment={attachment?attachment:null}/>
                    )
                })
            }
        </div>
        </Section>
    )
}

export default Projects
