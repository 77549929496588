import * as React from "react"
import Section from "../components/Section"

import Layout from "../components/layout"
import Projects from "../sections/Projects"
import Writing from "../sections/Writing"
import Hero from "../sections/Hero"


const IndexPage = () => (
  <Layout>
    <Hero />
    <Projects />
    <Writing/>
  
    <Section>
      <h2 className="text-white text-4xl font-bold text-center" >Thanks for visiting my portfolio</h2>
    </Section>
    
  </Layout>
)

export default IndexPage