import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Section, { SectionHeader } from '../../components/Section'
import Card from '../../components/Card'
import { ButtonLink } from '../../components/Button'

const Writing = () => {

    const data = useStaticQuery(graphql`
    query Writing {
        allContentfulWriting(sort: {order: DESC, fields: updatedAt}) {
          edges {
            node {
              id
              title
              url
              image {
                gatsbyImageData(placeholder: DOMINANT_COLOR)
              }
            }
          }
        }
      }
    `)
    const writings = data.allContentfulWriting.edges
    return (
        <Section>
        <SectionHeader title="Writing" subtitle="A collection of personal insights on current technological developments" />
        <div className="grid sm:grid-cols-3 gap-4">
            { 
                writings.map(writing => {
                    const { title, url, image } = writing.node
                    return (
                        <Card key={title} title={title} url={url} image={image} />
                    )
                })
            }
        </div>
        <div className="mt-12 grid justify-center">
            <ButtonLink to="https://medium.com/">View more at Medium</ButtonLink>
        </div>
        
        </Section>
    )
}

export default Writing
