import React from 'react'

const Section = ({ children }) => {
    return (
        <div style={{borderColor: '#3A434F'}} className="py-12 border-b">
            { children }
        </div>
    )
}
export const SectionHeader = ({ title, subtitle }) => {
    return (
        <div className="">
            <h2 className="text-blue-400 text-4xl font-bold text-center">{title}</h2>
            <p className="my-4 text-gray-300 text-md text-center">{subtitle}</p>
        </div>
    )
}

export default Section
