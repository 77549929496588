import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'

const Card = ({title, overline, description, image, url, attachment}) => {
    const fetchedImage = getImage(image)
    return (
        <a href={(attachment? attachment.file.url : url)} target="_blank" rel="noopener noreferrer" className="bg-gray-700 rounded-xl hover:opacity-60">
            <GatsbyImage alt={title} imgClassName="rounded-t-xl" className="rounded-t-xl" image={fetchedImage}/>
            <div className="px-6 py-4">
                <h5 className="text-blue-400 capitalize">{overline}</h5>
                <h3 className="text-white text-xl">{title}</h3>
                { description && <p className="text-gray-200 mt-2 ">{description}</p>}
            </div>
        </a>
    )
}

export default Card
