import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Typewriter from 'typewriter-effect'
import Section from '../../components/Section'

const greetings = [
    'Hello!',
    '¡Hola!',
    'Ciao!',
    'Salut!',
    'Hej!',
    'Hallo!',
    'Hei!',
    'Ola!',
    'Aloha!',
    'Salve!',
  ]

const Hero = () => {
    return (
        <Section>
            <div className="grid sm:grid-cols-3">
                <div className="grid mx-10 my-4 sm:my-0 justify-center align-middle sm:col-span-1 sm:justify-end">
                    <StaticImage imgClassName="rounded-full" className="w-48 h-48 rounded-full" src="../../images/avatar.png"/>
                </div>
                <div className="sm:col-span-2">
                <Typewriter
                    options={{
                    strings: greetings,
                    autoStart: true,
                    loop: true,
                    }}
                />
                <h1 className="text-white text-5xl font-bold">I'm Adam.</h1>
                <p className="md:max-w-lg sm:max-w-sm mt-4 text-gray-300 text-md">I am a front-end developer based in the United States. I specialize in React and JavaScript web applications and strive to create functional, intuitive, and engaging digital experiences.</p>
                </div>
            </div>
        </Section>
    )
}

export default Hero
